import { FaYoutube, FaTelegram, FaMedium, FaTwitter } from "react-icons/fa";

const data = [
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/Ceazor7",
  },
  {
    icon: <FaTelegram />,
    url: "https://guild.xyz/ask-the-sandwich",
  },
  {
    icon: <FaYoutube />,
    url: "https://www.youtube.com/c/CeazorsSnackSandwich",
  },
  {
    icon: <FaMedium />,
    url: "https://ceazor.medium.com/",
  },
];

export default data;
